import React from "react"
import { Children, cloneElement } from "react"
const Breadcrumbs = ({ filled, secondary, children }) => {
  const arrayChildren = Children.toArray(children)
  return (
    <div
      class={`breadcrumbs${secondary ? " secondary" : ""}${
        filled ? " filled" : ""
      }`}
    >
      {Children.map(arrayChildren, (child, index) => {
        const isLast = index === arrayChildren.length - 1
        return (
          <>
            {cloneElement(child, {
              isLast,
            })}
            {!isLast && <span class="iconography"> chevron_right </span>}
          </>
        )
      })}
    </div>
  )
}
export default Breadcrumbs
