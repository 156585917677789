import React, { useState } from "react";
// variants = info, success, warning, error
const TextInput = ({ secondary, variant, placeholder }) => {
  return (
    <input
      type="text"
      className={`${secondary ? "secondary" : ""}${
        variant ? ` ${variant}` : ""
      }`}
      placeholder={placeholder}
    />
  );
};
export default TextInput;
