import React from "react"
import { Link } from "gatsby"
const Breadcrumb = ({ children, link }) => {
  return (
    <div class="breadcrumb">
      {link ? <Link to={link}>{children}</Link> : <a>{children}</a>}
    </div>
  )
}
export default Breadcrumb
