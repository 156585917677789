import React from "react";
// variants = info, success, warning, error
const Upload = ({ name }) => (
  <>
    <label for={name} tabindex="0" class="file-upload">
      <span class="iconography"> upload_file </span> Upload File
    </label>
    <input name={name} id={name} type="file" class="file-input" />
  </>
);
export default Upload;
