import React from "react";
// variants = fullwidth
// secondary
const Select = ({ children, secondary, variant }) => {
  return (
    <select
      className={`${secondary ? "secondary" : ""}${
        variant ? ` ${variant}` : ""
      }`}
    >
      {children}
    </select>
  );
};
export default Select;
