import * as React from "react"
import "../assets/css/style.css"
import avatar from "../assets/images/sam.svg"
// import hero from "../assets/videos/estate.mp4"
import Avatar from "../components/display/avatar"
import Layout from "../components/layout"
import Boilerplate from "../components/layout/boilerplate"
import Section from "../components/layout/section"
import Container from "../components/layout/container"
import Alert from "../components/feedback/alert"
import Button from "../components/inputs/button"
import Select from "../components/inputs/select"
import TextInput from "../components/inputs/text"
import Upload from "../components/inputs/upload"
import Tooltip from "../components/display/tooltip"
// import Hero from "../components/surfaces/hero"
// import HeroVideo from "../components/surfaces/heroVideo"
import Breadcrumbs from "../components/navigation/breadcrumbs"
import Breadcrumb from "../components/navigation/breadcrumb"

const ComponentLibrary = () => {
  return (
    <Layout>
      <Boilerplate>
        {/* <Section>
          <Container>
            <HeroVideo hero={hero} />
          </Container>
        </Section> */}
        <Section>
          <Container>
            <h1>H1 Heading</h1>
            <h2>H2 Heading</h2>
            <h3>H3 Heading</h3>
            <h4>H4 Heading</h4>
            <h5>H5 Heading</h5>
            <h6>H6 Heading</h6>
            <p>
              Paragraph - Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Phasellus porttitor vitae orci tincidunt tempor. Donec non
              arcu mauris. Quisque euismod commodo eros sed convallis. In purus
              lorem, tempus vel lacus in, sagittis aliquam quam. Nullam
              tristique erat libero, eget faucibus mauris molestie faucibus.
              Mauris vel neque nisi. Duis id nunc magna. Orci varius natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus.
              Duis hendrerit interdum malesuada. Nulla feugiat vestibulum
              porttitor. Duis et sollicitudin nulla. Vivamus feugiat eros vel
              erat pulvinar vestibulum. Proin rhoncus convallis ante, non
              condimentum neque porta eget.
            </p>
            <p>
              Proin pulvinar imperdiet accumsan. Maecenas ipsum nisl, semper id
              diam a, luctus maximus justo. Ut fringilla metus ut tortor
              ultrices volutpat. Aenean imperdiet ornare enim eget scelerisque.
              Aenean faucibus, mi nec rhoncus gravida, magna neque ultricies
              metus, ut elementum urna sem rhoncus dolor. Nullam dictum
              tincidunt lacus, id faucibus augue tincidunt blandit. Proin ornare
              hendrerit ipsum eget euismod. Duis vel neque sed odio malesuada
              viverra vitae in neque. Vestibulum commodo ac quam nec tincidunt.
              Pellentesque et sodales purus, ac tincidunt justo. Morbi sed mi id
              leo egestas elementum eget ac lectus. Aenean quis libero rutrum,
              consectetur lectus ut, dignissim felis. Etiam vel nunc quis tellus
              rutrum venenatis non eu velit.
            </p>
            <h3>Breadcrumbs</h3>
            <Breadcrumbs>
              <Breadcrumb link="https://www.facebbok.com">Facebook</Breadcrumb>
              <Breadcrumb link="https://www.twitter.com">Twitter</Breadcrumb>
            </Breadcrumbs>
            <Breadcrumbs filled>
              <Breadcrumb link="https://www.facebbok.com">Facebook</Breadcrumb>
              <Breadcrumb link="https://www.twitter.com">Twitter</Breadcrumb>
            </Breadcrumbs>
            <h3>Alerts</h3>
            <Alert variant="info" title="Info">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              porttitor vitae orci tincidunt tempor. Donec non arcu mauris.
              Quisque euismod commodo eros sed convallis. In purus lorem, tempus
              vel lacus in, sagittis aliquam quam. Nullam tristique erat libero,
              eget faucibus mauris molestie faucibus.
            </Alert>
            <Alert variant="error" title="Error">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              porttitor vitae orci tincidunt tempor. Donec non arcu mauris.
              Quisque euismod commodo eros sed convallis. In purus lorem, tempus
              vel lacus in, sagittis aliquam quam. Nullam tristique erat libero,
              eget faucibus mauris molestie faucibus.
            </Alert>
            <Alert variant="warning" title="Warning">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              porttitor vitae orci tincidunt tempor. Donec non arcu mauris.
              Quisque euismod commodo eros sed convallis. In purus lorem, tempus
              vel lacus in, sagittis aliquam quam. Nullam tristique erat libero,
              eget faucibus mauris molestie faucibus.
            </Alert>
            <Alert variant="success" title="Success">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              porttitor vitae orci tincidunt tempor. Donec non arcu mauris.
              Quisque euismod commodo eros sed convallis. In purus lorem, tempus
              vel lacus in, sagittis aliquam quam. Nullam tristique erat libero,
              eget faucibus mauris molestie faucibus.
            </Alert>
            <h3>Inputs</h3>
            <TextInput placeholder="Text Input" />
            <TextInput variant="fullwidth" placeholder="Fullwidth Text Input" />
            <Select>
              <option>Option 1</option>
              <option>Option 2</option>
              <option>Option 3</option>
            </Select>
            <Select variant="fullwidth">
              <option>Fullwidth Option 1</option>
              <option>Fullwidth Option 2</option>
              <option>Fullwidth Option 3</option>
            </Select>
            <Upload name="drivinglicense" />
            <Button variant="large">Large</Button>
            <Button>Default</Button>
            <Button variant="small">Small</Button>
            <Button variant="outlined">Outlined</Button>
            <Button variant="info">Info Button</Button>
            <Button variant="error">Error Button</Button>
            <Button variant="warning">Warning Button</Button>
            <Button variant="success">Success Button</Button>
            <Button variant="fullwidth">Fullwidth</Button>
            <h3>Avatars</h3>
            <Avatar avatar={avatar} initials="SG" size="small" />
            <Avatar avatar={avatar} initials="SG" />
            <Avatar size="large" avatar={avatar} initials="SG" />
            <Avatar size="small" initials="SG" />
            <Avatar initials="SG" />
            <Avatar size="large" initials="SG" />
            <h3>Tooltip</h3>
            <Tooltip tooltip="This is the tooltip!">
              This is the trigger text!
            </Tooltip>
          </Container>
        </Section>
        <Section secondary>
          <Container>
            <h1>H1 Heading</h1>
            <h2>H2 Heading</h2>
            <h3>H3 Heading</h3>
            <h4>H4 Heading</h4>
            <h5>H5 Heading</h5>
            <h6>H6 Heading</h6>
            <p>
              Paragraph - Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Phasellus porttitor vitae orci tincidunt tempor. Donec non
              arcu mauris. Quisque euismod commodo eros sed convallis. In purus
              lorem, tempus vel lacus in, sagittis aliquam quam. Nullam
              tristique erat libero, eget faucibus mauris molestie faucibus.
              Mauris vel neque nisi. Duis id nunc magna. Orci varius natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus.
              Duis hendrerit interdum malesuada. Nulla feugiat vestibulum
              porttitor. Duis et sollicitudin nulla. Vivamus feugiat eros vel
              erat pulvinar vestibulum. Proin rhoncus convallis ante, non
              condimentum neque porta eget.
            </p>
            <p>
              Proin pulvinar imperdiet accumsan. Maecenas ipsum nisl, semper id
              diam a, luctus maximus justo. Ut fringilla metus ut tortor
              ultrices volutpat. Aenean imperdiet ornare enim eget scelerisque.
              Aenean faucibus, mi nec rhoncus gravida, magna neque ultricies
              metus, ut elementum urna sem rhoncus dolor. Nullam dictum
              tincidunt lacus, id faucibus augue tincidunt blandit. Proin ornare
              hendrerit ipsum eget euismod. Duis vel neque sed odio malesuada
              viverra vitae in neque. Vestibulum commodo ac quam nec tincidunt.
              Pellentesque et sodales purus, ac tincidunt justo. Morbi sed mi id
              leo egestas elementum eget ac lectus. Aenean quis libero rutrum,
              consectetur lectus ut, dignissim felis. Etiam vel nunc quis tellus
              rutrum venenatis non eu velit.
            </p>
            <h3>Breadcrumbs</h3>
            <Breadcrumbs secondary>
              <Breadcrumb link="https://www.facebbok.com">Facebook</Breadcrumb>
              <Breadcrumb link="https://www.twitter.com">Twitter</Breadcrumb>
            </Breadcrumbs>
            <Breadcrumbs secondary filled>
              <Breadcrumb link="https://www.facebbok.com">Facebook</Breadcrumb>
              <Breadcrumb link="https://www.twitter.com">Twitter</Breadcrumb>
            </Breadcrumbs>
            <h3>Alerts</h3>
            <Alert variant="info" title="Info">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              porttitor vitae orci tincidunt tempor. Donec non arcu mauris.
              Quisque euismod commodo eros sed convallis. In purus lorem, tempus
              vel lacus in, sagittis aliquam quam. Nullam tristique erat libero,
              eget faucibus mauris molestie faucibus.
            </Alert>
            <Alert variant="error" title="Error">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              porttitor vitae orci tincidunt tempor. Donec non arcu mauris.
              Quisque euismod commodo eros sed convallis. In purus lorem, tempus
              vel lacus in, sagittis aliquam quam. Nullam tristique erat libero,
              eget faucibus mauris molestie faucibus.
            </Alert>
            <Alert variant="warning" title="Warning">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              porttitor vitae orci tincidunt tempor. Donec non arcu mauris.
              Quisque euismod commodo eros sed convallis. In purus lorem, tempus
              vel lacus in, sagittis aliquam quam. Nullam tristique erat libero,
              eget faucibus mauris molestie faucibus.
            </Alert>
            <Alert variant="success" title="Success">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              porttitor vitae orci tincidunt tempor. Donec non arcu mauris.
              Quisque euismod commodo eros sed convallis. In purus lorem, tempus
              vel lacus in, sagittis aliquam quam. Nullam tristique erat libero,
              eget faucibus mauris molestie faucibus.
            </Alert>
            <h3>Inputs</h3>
            <TextInput secondary placeholder="Secondary Text Input" />
            <TextInput
              secondary
              variant="fullwidth"
              placeholder="Secondary Fullwidth Text Input"
            />
            <Select secondary>
              <option>Secondary Option 1</option>
              <option>Secondary Option 2</option>
              <option>Secondary Option 3</option>
            </Select>
            <Select variant="fullwidth" secondary>
              <option>Secondary Fullwidth Option 1</option>
              <option>Secondary Fullwidth Option 2</option>
              <option>Secondary Fullwidth Option 3</option>
            </Select>
            <Upload name="passport" />
            <Button secondary variant="large">
              Secondary Large
            </Button>
            <Button secondary>Secondary Default</Button>
            <Button secondary variant="small">
              Secondary Small
            </Button>
            <Button secondary variant="outlined">
              Secondary Outlined
            </Button>
            <Button variant="info">Info Button</Button>
            <Button variant="error">Error Button</Button>
            <Button variant="warning">Warning Button</Button>
            <Button variant="success">Success Button</Button>
            <Button secondary variant="fullwidth">
              Secondary Fullwidth
            </Button>
            <h3>Avatars</h3>
            <Avatar avatar={avatar} initials="SG" size="small" />
            <Avatar avatar={avatar} initials="SG" />
            <Avatar size="large" avatar={avatar} initials="SG" />
            <Avatar size="small" initials="SG" />
            <Avatar initials="SG" />
            <Avatar size="large" initials="SG" />
            <h3>Tooltip</h3>
            <Tooltip tooltip="This is the tooltip!">
              This is the trigger text!
            </Tooltip>
          </Container>
        </Section>
      </Boilerplate>
    </Layout>
  )
}
export default ComponentLibrary
