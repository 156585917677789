import React, { useState } from "react";
// variants = info, success, warning, error
const Alert = ({ variant, title, children }) => {
  const [show, setShow] = useState(true);
  return (
    show && (
      <div className={`alert${variant && ` ${variant}`}`}>
        <div class="alert-head">
          <div class="alert-title">
            <span class="iconography alert-icon">
              {variant === "info"
                ? "info"
                : variant === "error"
                ? "error"
                : variant === "warning"
                ? "warning"
                : (variant = "success" ? "check_circle" : "")}
            </span>
            {title}
          </div>
          <span onClick={() => setShow(false)} class="iconography alert-close">
            {" "}
            close{" "}
          </span>
        </div>
        <div class="alert-body">{children}</div>
      </div>
    )
  );
};
export default Alert;
