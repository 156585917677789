import React from "react"
const Tooltip = ({ tooltip, children }) => {
  return (
    <span class="tooltip">
      {children}
      <span class="tooltip-text">{tooltip}</span>
    </span>
  )
}
export default Tooltip
